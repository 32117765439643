body {
  background: #fff;
  margin: 0;
}

.main {
  width: 100%;
  height: 100vh;
  grid-template-columns: 1fr 3fr;
  display: grid;
}

#outlet {
  padding: 10px;
}

/*# sourceMappingURL=index.56bd05b3.css.map */
