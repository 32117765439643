body {
    background: #fff;
    margin: 0;
}

.main {
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr 3fr;
}
#outlet {
    padding: 10px;
}
